<template>
  <div class="layout-main">
    <company-logo />
    <router-view></router-view>
    <app-bar />
  </div>
</template>

<script>
import { Camera, } from '@capacitor/camera'
import { Geolocation, } from '@capacitor/geolocation'
import { PushNotifications, } from '@capacitor/push-notifications'
import { Device, } from '@capacitor/device'

export default {
  name: 'MainLayout',
  mounted() {
    PushNotifications.requestPermissions().then(() => {
      Geolocation.requestPermissions().then(() => {
        this.requestPermission()
      })
    })
  },
  methods: {
    async requestPermission() {
      const info = await Device.getInfo()
      if (Number(info.androidSDKVersion) >= 29) {
        await Camera.requestPermissions()
      }
    },
  },
}
</script>

<style lang="scss">
.layout-main {
  min-height: 95vh;
  padding-bottom: calc(80px + env(safe-area-inset-bottom));
  padding-top: calc(0px + env(safe-area-inset-top));
  background-color: #F5F5FA;
}

.n-input {
  margin-bottom: 20px;
  width: 370px;

  .n-content {
    border: 2px solid #CFCECE !important;
    border-radius: 16px !important;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1) !important;
  }
}

.btn {
  &.selected {
    background-color: var(--primary);
  }
}
</style>
